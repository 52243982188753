/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";

import {
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  DialogActions,
  Grid,
} from "@material-ui/core";

import { AppContext } from "../stores/AppStore";
import { UserContext } from "../stores/UserStore";
import { OctgContext } from "../stores/OctgStore";

import requests from "../api/api";

import PageContainer from "../components/layout/PageContainer";
import PageBody from "../components/layout/PageBody";
import LoadingOverlay from "../components/common/LoadingOverlay";

import DataTable from "../components/table/DataTable";
import Button from "../components/common/Button";

import ListIcon from "@material-ui/icons/List";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBackSharp";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import UpdateIcon from "@material-ui/icons/Update";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FileDownloadIcon from "@material-ui/icons/Download";

import { purple, red } from "@material-ui/core/colors";

import { formatDataForDisplay } from "../utils/format-data";

import { DropzoneAreaBase } from "material-ui-dropzone";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  titleHead: {
    color: "rgb(89, 203, 228)",
    fontSize: 24,
    fontWeight: 700,
    marginRight: 0,
  },
  dialogTitle: {
    padding: "10px 24px 0px 24px",
  },
  dialogContent: {
    minHeight: "50vh",
  },
  textField: {
    width: "calc(100% - 20px)",
    margin: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  detailGrid: {
    width: window.innerWidth,
    margin: "0 20px",
    fontSize: 13,
  },
  buttonPurple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
  buttonRed: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonValidate: {
    display: "flex",
    alignItems: "center",
    fontSize: 11,
    textTransform: "uppercase",
    backgroundColor: purple[500],
    color: theme.palette.getContrastText(purple[500]),
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
  list: {
    width: "calc(100% - 20px)",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    margin: "0px",
    padding: "0px",
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
  },
  listItemIcon: {
    minWidth: "32px",
  },
  listItemText: {
    margin: "0px",
    padding: "0px",
    fontSize: "13px",
  },
  card: {
    minHeight: "100%",
    marginRight: "10px",
  },
  validationErrorText: {
    marginTop: 0,
    color: theme.palette.error.main,
  },
  uploadContent: {
    minHeight: "100px",
  },
  downloadIcon: {
    position: "absolute",
    right: 8,
    top: 8,
  },
}));

const OctgViewActualData = () => {
  const history = useHistory();
  const classes = useStyles();
  const { loadId }: any = useParams();
  const { showSuccessMessage, showErrorMessage } = useContext(AppContext);
  const { currentUser } = useContext(UserContext);

  const { octgActualState, octgActualDispatch } = useContext(OctgContext);
  const [isLoading, setLoading] = useState(true);

  const [defaultColumns, setDefaultColumns] = useState([]);
  const [currentValidationErrors, setCurrentValidationErrors] = useState([]);
  const [validateComment, setValidateComment] = useState(undefined);

  const [showSelectColumnDialog, setShowSelectColumnDialog] = useState(false);
  const [showValidateDialog, setShowValidateDialog] = useState(false);
  const [showValidationErrorsDialog, setShowValidationErrorsDialog] =
    useState(false);
  const [showApplyHeatDialog, setShowApplyHeatDialog] = useState(false);

  const [upload, setUpload] = useState<any>();
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const [isEnable, setEnable] = useState(true);
  const [isUpload, setIsUpload] = useState(true);
  const [isChange, setIsChange] = useState(true);
  const [dialogObject, setDialogObject] = useState({
    dialogTitle: "Upload Certificate",
  });

  // const handleClickBack = () => {
  //     history.goBack()
  // };

  const handleClickShowSelectColumnDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowSelectColumnDialog(true);
  };

  const handleClickCloseSelectColumnDialog = () => {
    setShowSelectColumnDialog(false);
  };

  const handleClickValidate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowValidateDialog(true);
  };

  const handleClickShowValidationErrorsDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData
  ) => {
    setCurrentValidationErrors(rowData.validationErrors);
    setShowValidationErrorsDialog(true);
  };

  const handleClickCloseValidationErrorsDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowValidationErrorsDialog(false);
  };

  const handleCheckColumn = (rowData: any) => () => {
    const list = JSON.parse(JSON.stringify(defaultColumns));
    const column = list.find((x) => x.field === rowData.field);
    column.checked = column.checked ? !column.checked : true;
    setDefaultColumns(list);
  };

  const handleClickDownloadCertificate = (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData
  ) => {
    setLoading(true);
    requests
      .downloadCertificate(rowData.certificateId)
      .then((data) => {
        window.open(data, "_blank");
      })
      .catch((error) => {
        showErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownloadCertificate = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    requests
      .downloadCertificate(upload.selectCertificate)
      .then((data) => {
        window.open(data, "_blank");
      })
      .catch((error) => {
        showErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickUpload = (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData
  ) => {
    const record: any = {};
    record.selectCertificate = "0";
    record.productId = rowData.id;
    record.productHeat = rowData.heat;
    setUpload(record);
    setShowUploadDialog(true);
  };

  const doClickUpload = () => {
    if (upload.productHeat) {
      setShowApplyHeatDialog(true);
    } else {
      const params: any = {
        certificateNumber: upload.certificateNumber,
        revision: upload.revision,
        createdOn: upload.createdOn,
        modifiedOn: upload.modifiedOn,
        productId: upload.productId,
      };
      doUpload(params);
    }
  };
  const doClickUpdate = () => {
    if (upload.productHeat) {
      setShowApplyHeatDialog(true);
      setIsUpload(false);
      setDialogObject({
        dialogTitle: "Update Certificate",
      });
    } else {
      const params: any = {
        certificateNumber: upload.certificateNumber,
        revision: upload.revision,
        createdOn: upload.createdOn,
        modifiedOn: upload.modifiedOn,
        productId: upload.productId,
        selectCertificate: upload.selectCertificate,
      };
      doUpdate(params);
    }
  };

  const doClickApplyHeat = (isUpload: boolean) => {
    const params: any = {
      certificateNumber: upload.certificateNumber,
      revision: upload.revision,
      createdOn: upload.createdOn,
      modifiedOn: upload.modifiedOn,
      heat: upload.productHeat,
      productId: upload.productId,
      selectCertificate: upload.selectCertificate,
    };
    isUpload ? doUpload(params) : doUpdate(params);
  };

  const doUpload = (params: any) => {
    const file = upload.files[0].file;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async (e: ProgressEvent) => {
      setLoading(true);
      const byteArray = (e.target as FileReader).result;
      const base64 = transformFileToBase64(byteArray);

      params.data = base64;

      requests
        .uploadCertificate(params)
        .then((data) => {
          showSuccessMessage("Upload Data Success!");
          init();
        })
        .catch((error) => {
          showErrorMessage(error.message);
        })
        .finally(() => {
          setLoading(false);
          setUpload(null);
          setShowApplyHeatDialog(false);
          setShowUploadDialog(false);
          setEnable(true);
        });
    };
  };
  const doUpdate = (params: any) => {
    setLoading(true);
    requests
      .updateCertificate(params)
      .then((data) => {
        showSuccessMessage("Update Data Success!");
        init();
      })
      .catch((error) => {
        showErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
        setUpload(null);
        setShowApplyHeatDialog(false);
        setShowUploadDialog(false);
        setEnable(true);
      });
  };

  const transformFileToBase64 = (content: any) => {
    let binary = "";
    const bytes = new Uint8Array(content);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = btoa(binary);
    return base64;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const record = { ...upload };
    record[event.target.name] = event.target.value;
    setUpload(record);

    const certificate = octgActualState.octgUploadedCertificates.find(
      (x) => x.id === record.selectCertificate
    );
    setIsChange(handleChanges(certificate, record));
  };

  const handleChanges = (certificate: any, record: any): boolean => {
    const a = {
      certificateNumber: certificate.certificateNumber,
      revision: certificate.revision === null ? "" : certificate.revision,
      createdOn: certificate.createdOn
        ? format(new Date(certificate.createdOn), "yyyy-MM-dd")
        : "",
      modifiedOn: certificate.modifiedOn
        ? format(new Date(certificate.modifiedOn), "yyyy-MM-dd")
        : "",
    };
    const b = {
      certificateNumber: record.certificateNumber,
      revision: record.revision === null ? "" : record.revision,
      createdOn: record.createdOn,
      modifiedOn: record.modifiedOn,
    };

    let aProps = Object.getOwnPropertyNames(a);

    for (let i = 0; i < aProps.length; i++) {
      let propName = aProps[i];

      if (b[propName] !== a[propName]) {
        return false;
      }
    }
    return true;
  };

  const handleSelectCertificateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const record = { ...upload };
    record.selectCertificate = event.target.value;
    const certificate = octgActualState.octgUploadedCertificates.find(
      (x) => x.id === event.target.value
    );
    if (certificate) {
      record.certificateNumber = certificate.certificateNumber;
      record.revision = certificate.revision ? certificate.revision : "";
      record.createdOn = certificate.createdOn
        ? format(new Date(certificate.createdOn), "yyyy-MM-dd")
        : "";
      record.modifiedOn = certificate.modifiedOn
        ? format(new Date(certificate.modifiedOn), "yyyy-MM-dd")
        : "";
    }
    event.target.value === "0" ? setEnable(true) : setEnable(false);

    setUpload(record);
  };

  const handleUploadChange = (files) => {
    const record = { ...upload };
    record.files = files;
    setUpload(record);
  };

  const doClickCloseUpload = () => {
    setShowApplyHeatDialog(false);
    setUpload(null);
    setShowUploadDialog(false);
    setEnable(true);
  };

  useEffect(() => {
    init();
  }, [loadId]);

  useEffect(() => {
    const showedDetails = [];
    const showedColumns = [];
    const desfaultCellStyle = {
      whiteSpace: "nowrap",
      fontSize: "13px",
      margin: "0px",
      padding: "0px 15px",
    };
    for (const column of defaultColumns) {
      if (column.checked) {
        if (column.folded) {
          showedDetails.push({ title: column.title, field: column.field });
        } else {
          showedColumns.push({
            title: column.title,
            field: column.field,
            cellStyle: desfaultCellStyle,
          });
        }
      }
    }
    octgActualDispatch({
      type: "set_octg_actual_columns",
      octgActualColumns: showedColumns,
    });
    octgActualDispatch({
      type: "set_octg_actual_details",
      octgActualDetails: showedDetails,
    });
  }, [defaultColumns]);

  const init = () => {
    const defaultColumns = [
      {
        title: "Sales Order",
        field: "salesOrderNumber",
        checked: true,
        folded: false,
      },
      {
        title: "Sales Order Item",
        field: "salesItemNumber",
        checked: true,
        folded: false,
      },
      { title: "Client", field: "client.name", checked: true, folded: false },
      {
        title: "Client Order",
        field: "customerOrderNumber",
        checked: true,
        folded: false,
      },
      {
        title: "Client Ordem Item",
        field: "customerItemNumber",
        checked: true,
        folded: false,
      },
      {
        title: "ERP Reference",
        field: "erpReference",
        checked: true,
        folded: false,
      },
      {
        title: "ERP Description",
        field: "erpDescription",
        checked: true,
        folded: false,
      },
      {
        title: "Consignment Type",
        field: "consignmentType",
        checked: true,
        folded: false,
      },
      {
        title: "OD Nominal",
        field: "outerDiameterNominal",
        checked: true,
        folded: false,
      },
      {
        title: "Connection",
        field: "connection",
        checked: true,
        folded: false,
      },
      { title: "IPPN", field: "ippn", checked: true, folded: false },
      {
        title: "Vallourec ID",
        field: "vallourecId",
        checked: true,
        folded: false,
      },
      { title: "Heat", field: "heat", checked: true, folded: false },
      {
        title: "Length Nominal",
        field: "lengthNominal",
        checked: true,
        folded: false,
      },
      {
        title: "Weight Nominal",
        field: "weightNominal",
        checked: true,
        folded: false,
      },
      {
        title: "Makeup Loss",
        field: "makeupLoss",
        checked: true,
        folded: false,
      },
      {
        title: "Storage Type",
        field: "storageType",
        checked: true,
        folded: false,
      },
      {
        title: "Serial Number",
        field: "serialNumber",
        checked: true,
        folded: false,
      },
      { title: "Length Real", field: "length", checked: true, folded: false },
      { title: "Weight Real", field: "weight", checked: true, folded: false },
      // {
      //   title: "Internal Diameter Real",
      //   field: "internalDiameter",
      //   checked: true,
      //   folded: false,
      // },
      // {
      //   title: "Outer Diameter Real",
      //   field: "outerDiameter",
      //   checked: true,
      //   folded: false,
      // },
      {
        title: "Certificate",
        field: "certificateNumber",
        checked: true,
        folded: false,
      },
      { title: "Load", field: "loadId", checked: true, folded: true },
      { title: "Plant", field: "plantName", checked: true, folded: true },
      {
        title: "WT Nominal",
        field: "wallThicknessNominal",
        checked: true,
        folded: true,
      },
      { title: "Drift Type", field: "driftType", checked: true, folded: true },
      { title: "Standard", field: "standard", checked: true, folded: true },
      { title: "Revision", field: "revision", checked: true, folded: true },
      { title: "Grade", field: "grade", checked: true, folded: true },
      {
        title: "CQ Version",
        field: "qcVersionNumber",
        checked: true,
        folded: true,
      },
    ];

    setDefaultColumns(defaultColumns);

    loadUploadCertificates();
    loadTableData();
  };

  const loadUploadCertificates = () => {
    requests
      .getUploadedCertificates()
      .then((data) => {
        data = formatDataForDisplay(data);
        octgActualDispatch({
          type: "set_uploaded_certificates",
          octgUploadedCertificates: data,
        });
      })
      .catch((error) => {
        showErrorMessage(error.message);
      });
  };

  const loadTableData = () => {
    if (
      currentUser &&
      currentUser.roles.OCTG_DATA_VIEWER &&
      (currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER ||
        currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER)
    ) {
      setLoading(true);
      if (loadId) {
        requests
          .getOctgActualPendingData(loadId)
          .then((data) => {
            data = formatDataForDisplay(data);
            octgActualDispatch({
              type: "set_octg_actual_data",
              octgActualData: data,
            });
            octgActualDispatch({
              type: "set_octg_actual_load",
              octgActualLoad: data && data.length > 0 ? data[0].load : {},
            });
          })
          .catch((error) => {
            showErrorMessage(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        requests
          .getOctgActualValidatedData()
          .then((data) => {
            console.log("Data: ", data);
            data = formatDataForDisplay(data);
            octgActualDispatch({
              type: "set_octg_actual_data",
              octgActualData: data,
            });
            octgActualDispatch({
              type: "set_octg_actual_load",
              octgActualLoad: {},
            });
          })
          .catch((error) => {
            showErrorMessage(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      octgActualDispatch({ type: "set_octg_actual_data", octgActualData: [] });
      octgActualDispatch({ type: "set_octg_actual_load", octgActualLoad: {} });
      setLoading(false);
      showErrorMessage(
        "The user doesn´t have permissions to see this informations!"
      );
    }
  };

  const doClickCloseValidate = () => {
    setShowValidateDialog(false);
  };

  const doClickValidate = (action: string) => () => {
    setLoading(true);
    requests
      .validateData({
        loadId: loadId,
        action: action,
        comment: validateComment,
      })
      .then((data) => {
        history.replace("/octg/view/actual-data");
      })
      .catch((error) => {
        if (error.response.data) {
          const responseData = JSON.parse(error.response.data);
          if (typeof responseData === 'string' && (JSON.parse(responseData).hasOwnProperty("errors"))) {
            showErrorMessage(JSON.stringify(JSON.parse(responseData).errors));
            return;
          }
          console.log(typeof responseData);
          const tableData = JSON.parse(
            JSON.stringify(octgActualState.octgActualData)
          );
          let errorCount = 0;
          for (const row of tableData) {
            row.validationResult = "SUCCESS";
            const errors = responseData.filter((x) => x.target.id === row.id);
            errorCount++;
            if (errors) {
              row.validationResult = "ERROR";
              row.validationErrors = [];
              for (const error of errors) {
                let fieldError = row.validationErrors.find(
                  (x) => x.id === error.property
                );
                if (!fieldError) {
                  fieldError = {
                    id: error.property,
                    title: defaultColumns.find(
                      (x) => x.field === error.property
                    ).title,
                    messages: [],
                  };
                  row.validationErrors.push(fieldError);
                }
                for (const key of Object.keys(error.constraints)) {
                  let message = error.constraints[key];
                  message = message.replace(fieldError.id + " ", "");
                  message = message.charAt(0).toUpperCase() + message.slice(1);
                  fieldError.messages.push(message);
                }
              }
            }
          }
          octgActualDispatch({
            type: "set_octg_actual_data",
            octgActualData: tableData,
          });
          octgActualDispatch({
            type: "set_octg_actual_load",
            octgActualLoad:
              tableData && tableData.length > 0 ? tableData[0].load : {},
          });
          showErrorMessage(
            "The validation returns " + errorCount + " error(s)!"
          );
        } else {
          showErrorMessage(error.message);
        }
      })
      .finally(() => {
        setShowValidateDialog(false);
        setLoading(false);
      });
  };

  const ValidateIcon = () => {
    return (
      <div className={classes.buttonValidate}>
        <PlaylistAddCheckIcon fontSize="large" />
        <span>Validate</span>
      </div>
    );
  };

  const renderTableData = useMemo(
    () => (
      <DataTable
        title="View OCTG Actual Data"
        style={{ color: "rgb(89, 203, 228)" }}
        filtering={true}
        columns={octgActualState.octgActualColumns}
        data={octgActualState.octgActualData}
        // editable={
        //     currentUser && currentUser.roles.OCTG_DATA_VIEWER && (currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER || currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER) && octgActualState.octgActualLoad.status === "Pending" && {
        //     onRowUpdate: (newData, oldData) =>
        //         new Promise<void>((resolve, reject) => {
        //             setTimeout(() => {
        //                 console.log(newData);
        //                 resolve();
        //             }, 1000);
        //         })
        //     }
        // }

        actions={[
          // { icon: () => <ArrowBackIcon color="primary" />, tooltip: 'Go Back', onClick: (event) => handleClickBack(), isFreeAction: true },
          {
            icon: () => <RefreshIcon color="primary" />,
            tooltip: "Refresh",
            onClick: (event) => loadTableData(),
            isFreeAction: true,
          },
          {
            icon: () => <ListIcon data-cy="selectColumns" color="primary" />,
            tooltip: "Select Columns",
            onClick: handleClickShowSelectColumnDialog,
            isFreeAction: true,
          },
          {
            icon: () => <ValidateIcon />,
            tooltip: "Validate Selected Itens",
            onClick: handleClickValidate,
            isFreeAction: true,
            hidden: loadId
              ? currentUser &&
                currentUser.roles.OCTG_DATA_VIEWER &&
                currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER
                ? octgActualState.octgActualLoad.status === "Pending"
                  ? false
                  : true
                : true
              : true,
          },
          (rowData) => ({
            icon: () => (
              <PictureAsPdfIcon
                color={rowData.certificateId ? "primary" : "disabled"}
              />
            ),
            tooltip: "Download Certificate",
            onClick: handleClickDownloadCertificate,
            disabled: !rowData.certificateId,
          }),
          (rowData) => ({
            icon: () => <CloudUploadIcon color="secondary" />,
            tooltip: "Upload Certificate",
            onClick: handleClickUpload,
            hidden:
              currentUser &&
              currentUser.roles.OCTG_DATA_VIEWER &&
              currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER
                ? octgActualState.octgActualLoad.status === "Pending"
                  ? false
                  : true
                : true,
          }),
          (rowData) => ({
            icon: () => <ErrorIcon color="error" />,
            tooltip: "Show Validation Erros",
            onClick: handleClickShowValidationErrorsDialog,
            hidden: !loadId || rowData.validationResult !== "ERROR",
          }),
          (rowData) => ({
            icon: () => <CheckCircleIcon color="secondary" />,
            tooltip: "Validation Sucsess",
            disabled: true,
            hidden: !loadId || rowData.validationResult !== "SUCCESS",
          }),
        ]}
        detailPanel={(rowData) => {
          return (
            <Grid container className={classes.detailGrid} spacing={1}>
              {octgActualState.octgActualDetails.map((column) => {
                return (
                  <Grid key={column.field} item xs={4}>
                    <b>{column.title}:</b> {rowData[column.field]}
                  </Grid>
                );
              })}
            </Grid>
          );
        }}
      />
    ),
    [
      octgActualState.octgActualColumns,
      octgActualState.octgActualData,
      octgActualState.octgActualLoad,
    ]
  );

  const renderListSelectColumns = useMemo(
    () => (
      <List className={classes.list} dense component="div" role="list">
        {defaultColumns.map((column) => {
          const labelId = `column-list-label-${column.field}`;
          return (
            <ListItem
              key={column.field}
              className={classes.listItem}
              role={undefined}
              dense
              button
              onClick={handleCheckColumn(column)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  data-cy="listItem"
                  className={classes.checkbox}
                  edge="start"
                  checked={column.checked ? column.checked : false}
                  tabIndex={-1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                className={classes.listItemText}
                primary={column.title}
              />
            </ListItem>
          );
        })}
      </List>
    ),
    [defaultColumns, showSelectColumnDialog]
  );

  const renderListValidationErrors = useMemo(
    () => (
      <Grid container spacing={0}>
        {currentValidationErrors.map((error) => {
          return (
            <Grid key={error.id} container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <b>{error.title}:</b>
              </Grid>
              <Grid container item xs={9} spacing={0}>
                <ul className={classes.validationErrorText}>
                  {error.messages.map((message) => {
                    return <li key={error.id + "_" + message}>{message}</li>;
                  })}
                </ul>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    ),
    [currentValidationErrors, showValidationErrorsDialog]
  );

  return (
    <PageContainer className={classes.root}>
      <PageBody>{renderTableData}</PageBody>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showSelectColumnDialog}
        aria-labelledby="column-selected-dialog"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="column-selected-dialog"
        >
          <IconButton
            color="primary"
            component="span"
            onClick={handleClickCloseSelectColumnDialog}
          >
            <ArrowBackIcon />
          </IconButton>
          Select Columns
        </DialogTitle>

        <DialogContent dividers={false} className={classes.dialogContent}>
          {renderListSelectColumns}
        </DialogContent>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showValidateDialog}
        aria-labelledby="validate-dialog"
      >
        <DialogTitle className={classes.dialogTitle} id="validate-dialog">
          Validate Loads
        </DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText>
            Do you want validate load number "{loadId}"?
          </DialogContentText>

          <DialogContent dividers={false} className={classes.dialogContent}>
            <TextField
              multiline
              rows={20}
              placeholder="Comments"
              id="settings"
              name="settings"
              // error={validateComment !== undefined && !validateComment}
              value={validateComment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValidateComment(event.target.value)
              }
              variant="outlined"
              fullWidth
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={doClickCloseValidate}
          >
            Close
          </Button>
          <Button
            disabled={!validateComment}
            className={classes.buttonRed}
            variant="contained"
            color="Error"
            startIcon={<ErrorIcon />}
            onClick={doClickValidate("Rejected")}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckCircleIcon />}
            onClick={doClickValidate("Validated")}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showUploadDialog}
        aria-labelledby="upload-dialog"
      >
        <DialogTitle className={classes.dialogTitle} id="upload-dialog">
          Upload Certificate
          <IconButton
            component="span"
            color="primary"
            disabled={isEnable || !isChange}
            aria-label="download"
            className={classes.downloadIcon}
            onClick={handleDownloadCertificate}
          >
            <FileDownloadIcon />
          </IconButton>
        </DialogTitle>
        {upload && (
          <DialogContent dividers={false} className={classes.dialogContent}>
            <TextField
              select
              label="Select Certificate:"
              id="selectCertificate"
              name="selectCertificate"
              value={upload.selectCertificate}
              onChange={handleSelectCertificateChange}
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value="0">Select a Certificate</MenuItem>
              {octgActualState &&
                octgActualState.octgUploadedCertificates.map((certificate) => (
                  <MenuItem key={certificate.id} value={certificate.id}>
                    {certificate.certificateNumber}{" "}
                    {certificate.revision ? " / " + certificate.revision : ""}
                  </MenuItem>
                ))}
            </TextField>

            {isEnable && (
              <DropzoneAreaBase
                dropzoneClass={classes.uploadContent}
                dropzoneText="Click or drag a Certificate file to upload"
                acceptedFiles={["application/pdf"]}
                maxFileSize={6291456} //6mb
                showFileNames={true}
                useChipsForPreview={true}
                filesLimit={1}
                fileObjects={upload.files}
                onAdd={(newFileObjs) => {
                  handleUploadChange(newFileObjs);
                }}
                onDelete={(deleteFileObj) => {
                  handleUploadChange([]);
                }}
              />
            )}

            <TextField
              label="Certificate Ref:"
              id="certificateNumber"
              name="certificateNumber"
              required
              error={
                upload.certificateNumber !== undefined &&
                !upload.certificateNumber
              }
              value={upload.certificateNumber}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Revision:"
              id="revision"
              name="revision"
              value={upload.revision}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Creation Date:"
              id="createdOn"
              name="createdOn"
              type="date"
              value={upload.createdOn}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Modification Date:"
              id="modifiedOn"
              name="modifiedOn"
              type="date"
              value={upload.modifiedOn}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={doClickCloseUpload}
          >
            Close
          </Button>
          {isEnable && (
            <Button
              variant="contained"
              color="primary"
              disabled={
                upload &&
                (!upload.files ||
                  (upload.files && upload.files.length === 0) ||
                  !upload.productId ||
                  !upload.certificateNumber)
              }
              startIcon={<CloudUploadIcon />}
              onClick={doClickUpload}
            >
              Upload
            </Button>
          )}
          {!isEnable && (
            <Button // aqui
              variant="contained"
              color="primary"
              disabled={isChange}
              startIcon={<UpdateIcon />}
              onClick={doClickUpdate}
            >
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showValidationErrorsDialog}
        aria-labelledby="validation-errors-dialog"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="validation-errors-dialog"
        >
          <IconButton
            color="primary"
            component="span"
            onClick={handleClickCloseValidationErrorsDialog}
          >
            <ArrowBackIcon />
          </IconButton>
          Validation Errors
        </DialogTitle>

        <DialogContent dividers={false} className={classes.dialogContent}>
          {renderListValidationErrors}
        </DialogContent>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showApplyHeatDialog}
        aria-labelledby="delete-dialog"
      >
        <DialogTitle className={classes.dialogTitle} id="delete-dialog">
          {dialogObject.dialogTitle}
        </DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText>
            All pipes linked to this certificate will be impacted Do you
            confirm?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CancelIcon />}
            onClick={() => setShowApplyHeatDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DoneAllIcon />}
            onClick={() => doClickApplyHeat(isUpload)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <LoadingOverlay open={isLoading} />
    </PageContainer>
  );
};

export default OctgViewActualData;
