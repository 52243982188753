/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  DialogActions,
  Grid,
} from "@material-ui/core";

import { AppContext } from "../stores/AppStore";
import { UserContext } from "../stores/UserStore";
import { OctgContext } from "../stores/OctgStore";

import requests from "../api/api";

import PageContainer from "../components/layout/PageContainer";
import PageBody from "../components/layout/PageBody";
import LoadingOverlay from "../components/common/LoadingOverlay";

import DataTable from "../components/table/DataTable";
import Button from "../components/common/Button";

import ListIcon from "@material-ui/icons/List";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBackIcon from "@material-ui/icons/ArrowBackSharp";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { formatDataForDisplay } from "../utils/format-data";

import { purple, red } from "@material-ui/core/colors";
//import { SocialDistance } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  titleHead: {
    color: "rgb(89, 203, 228)",
    fontSize: 24,
    fontWeight: 700,
    marginRight: 0,
  },
  dialogTitle: {
    padding: "10px 24px 0px 24px",
  },
  dialogContent: {
    minHeight: "50vh",
  },
  textField: {
    width: "calc(100% - 20px)",
    margin: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  detailGrid: {
    width: window.innerWidth,
    margin: "0 20px",
    fontSize: 13,
  },
  buttonPurple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
  buttonRed: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonValidate: {
    display: "flex",
    alignItems: "center",
    fontSize: 11,
    textTransform: "uppercase",
    backgroundColor: purple[500],
    color: theme.palette.getContrastText(purple[500]),
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    "&:hover": {
      backgroundColor: purple[700],
    },
  },
  list: {
    width: "calc(100% - 20px)",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    margin: "0px",
    padding: "0px",
  },
  checkbox: {
    margin: "0px",
    padding: "0px",
  },
  listItemIcon: {
    minWidth: "32px",
  },
  listItemText: {
    margin: "0px",
    padding: "0px",
    fontSize: "13px",
  },
  card: {
    minHeight: "100%",
    marginRight: "10px",
  },
  validationErrorText: {
    marginTop: 0,
    color: theme.palette.error.main,
  },
}));

const OctgViewNominalData = () => {
  const history = useHistory();
  const classes = useStyles();
  const { loadId }: any = useParams();
  const { showErrorMessage } = useContext(AppContext);
  const { currentUser } = useContext(UserContext);

  const { octgNominalState, octgNominalDispatch } = useContext(OctgContext);
  const [isLoading, setLoading] = useState(true);

  const [defaultColumns, setDefaultColumns] = useState([]);
  const [currentValidationErrors, setCurrentValidationErrors] = useState([]);
  const [validateComment, setValidateComment] = useState(undefined);

  const [showSelectColumnDialog, setShowSelectColumnDialog] = useState(false);
  const [showValidateDialog, setShowValidateDialog] = useState(false);
  const [showValidationErrorsDialog, setShowValidationErrorsDialog] =
    useState(false);

  // const handleClickBack = () => {
  //     history.goBack()
  // };

  const handleClickShowSelectColumnDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowSelectColumnDialog(true);
  };

  const handleClickCloseSelectColumnDialog = () => {
    setShowSelectColumnDialog(false);
  };

  const handleClickValidate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowValidateDialog(true);
  };

  const handleClickShowValidationErrorsDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData
  ) => {
    setCurrentValidationErrors(rowData.validationErrors);
    setShowValidationErrorsDialog(true);
  };

  const handleClickCloseValidationErrorsDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowValidationErrorsDialog(false);
  };

  const handleCheckColumn = (rowData: any) => () => {
    const list = JSON.parse(JSON.stringify(defaultColumns));
    const column = list.find((x) => x.field === rowData.field);
    column.checked = column.checked ? !column.checked : true;
    setDefaultColumns(list);
  };

  useEffect(() => {
    init();
  }, [loadId]);

  useEffect(() => {
    const showedDetails = [];
    const showedColumns = [];
    const desfaultCellStyle = {
      whiteSpace: "nowrap",
      fontSize: "13px",
      margin: "0px",
      padding: "0px 15px",
    };
    for (const column of defaultColumns) {
      if (column.checked) {
        if (column.folded) {
          showedDetails.push({ title: column.title, field: column.field });
        } else {
          showedColumns.push({
            title: column.title,
            field: column.field,
            cellStyle: desfaultCellStyle,
          });
        }
      }
    }
    octgNominalDispatch({
      type: "set_octg_nominal_columns",
      octgNominalColumns: showedColumns,
    });
    octgNominalDispatch({
      type: "set_octg_nominal_details",
      octgNominalDetails: showedDetails,
    });
  }, [defaultColumns]);

  const init = () => {
    const defaultColumns = [
      {
        title: "ERP Reference",
        field: "erpReference",
        checked: true,
        folded: false,
      },
      {
        title: "ERP Description",
        field: "erpDescription",
        checked: true,
        folded: false,
      },
      { title: "Client", field: "client.name", checked: true, folded: false },
      {
        title: "OD Nominal",
        field: "outerDiameter",
        checked: true,
        folded: false,
      },
      { title: "Weight", field: "weight", checked: true, folded: false },
      {
        title: "WT Nominal",
        field: "wallThickness",
        checked: true,
        folded: false,
      },
      { title: "Grade", field: "grade", checked: true, folded: false },
      {
        title: "Connection",
        field: "connection",
        checked: true,
        folded: false,
      },
      {
        title: "Option",
        field: "couplingOption",
        checked: true,
        folded: false,
      },
      {
        title: "Product Type",
        field: "productType",
        checked: true,
        folded: false,
      },
      { title: "Length", field: "length", checked: true, folded: false },
      { title: "Drift Type", field: "driftType", checked: true, folded: false },
      { title: "Standard", field: "standard", checked: true, folded: false },
      { title: "Revision", field: "revision", checked: true, folded: false },
      {
        title: "Makeup Loss",
        field: "makeupLoss",
        checked: true,
        folded: false,
      },
      {
        title: "Serial Number",
        field: "serialNumber",
        checked: true,
        folded: false,
      },
      {
        title: "Is Modified Product",
        field: "isModifiedProduct",
        checked: true,
        folded: false,
      },
      {
        title: "Modified Product Desc",
        field: "modifiedProductDesc",
        checked: true,
        folded: false,
      },
      {
        title: "OD Nominal 2",
        field: "outerDiameterE2",
        checked: true,
        folded: true,
      },
      { title: "Weight 2", field: "weightE2", checked: true, folded: true },
      {
        title: "Connection 2",
        field: "connectionE2",
        checked: true,
        folded: true,
      },
      {
        title: "Option 2",
        field: "couplingOptionE2",
        checked: true,
        folded: true,
      },
      { title: "Grade 2", field: "gradeE2", checked: true, folded: true },
      {
        title: "WT Nominal 2",
        field: "wallThicknessE2",
        checked: true,
        folded: true,
      },
      {
        title: "Makeup Loss 2",
        field: "makeupLossE2",
        checked: true,
        folded: true,
      },
    ];

    setDefaultColumns(defaultColumns);

    loadTableData();
  };

  const loadTableData = () => {
    if (
      currentUser &&
      currentUser.roles.OCTG_DATA_VIEWER &&
      (currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER ||
        currentUser.roles.OCTG_DATA_VIEWER.DATA_UPLOADER)
    ) {
      setLoading(true);
      if (loadId) {
        requests
          .getOctgNominalPendingData(loadId)
          .then((data) => {
            data = formatDataForDisplay(data);
            octgNominalDispatch({
              type: "set_octg_nominal_data",
              octgNominalData: data,
            });
            octgNominalDispatch({
              type: "set_octg_nominal_load",
              octgNominalLoad: data && data.length > 0 ? data[0].load : {},
            });
          })
          .catch((error) => {
            showErrorMessage(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        requests
          .getOctgNominalValidatedData()
          .then((data) => {
            data = formatDataForDisplay(data);
            octgNominalDispatch({
              type: "set_octg_nominal_data",
              octgNominalData: data,
            });
            octgNominalDispatch({
              type: "set_octg_nominal_load",
              octgNominalLoad: {},
            });
          })
          .catch((error) => {
            showErrorMessage(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      octgNominalDispatch({
        type: "set_octg_nominal_data",
        octgNominalData: [],
      });
      octgNominalDispatch({
        type: "set_octg_nominal_load",
        octgNominalLoad: {},
      });
      setLoading(false);
      showErrorMessage(
        "The user doesn´t have permissions to see this informations!"
      );
    }
  };

  const doClickCloseValidate = () => {
    setShowValidateDialog(false);
  };

  const doClickValidate = (action: string) => () => {
    setLoading(true);
    requests
    .validateData(
      { loadId: loadId, action: action, comment: validateComment })
      .then((data) => {
        history.replace("/octg/view/nominal-data");
      })
      .catch((error) => {
        if (error.response.data) {
          const responseData = JSON.parse(error.response.data);
          const tableData = JSON.parse(
            JSON.stringify(octgNominalState.octgNominalData)
          );
          let errorCount = 0;
          for (const row of tableData) {
            row.validationResult = "SUCCESS";
            const errors = responseData.filter((x) => x.target.id === row.id);
            errorCount++;
            if (errors) {
              row.validationResult = "ERROR";
              row.validationErrors = [];
              for (const error of errors) {
                let fieldError = row.validationErrors.find(
                  (x) => x.id === error.property
                );
                if (!fieldError) {
                  fieldError = {
                    id: error.property,
                    title: defaultColumns.find(
                      (x) => x.field === error.property
                    ).title,
                    messages: [],
                  };
                  row.validationErrors.push(fieldError);
                }
                for (const key of Object.keys(error.constraints)) {
                  let message = error.constraints[key.toString()];
                  message = message.replace(fieldError.id + " ", "");
                  message = message.charAt(0).toUpperCase() + message.slice(1);
                  fieldError.messages.push(message);
                }
              }
            }
          }
          octgNominalDispatch({
            type: "set_octg_nominal_data",
            octgNominalData: tableData,
          });
          octgNominalDispatch({
            type: "set_octg_nominal_load",
            octgNominalLoad:
              tableData && tableData.length > 0 ? tableData[0].load : {},
          });
          showErrorMessage(
            "The validation returns " + errorCount + " error(s)!"
          );
        } else {
          showErrorMessage(error.message);
        }
      })
      .finally(() => {
        setShowValidateDialog(false);
        setLoading(false);
      });
  };

  const ValidateIcon = () => {
    return (
      <div className={classes.buttonValidate}>
        <PlaylistAddCheckIcon fontSize="large" />
        <span>Validate</span>
      </div>
    );
  };

  const renderTableData = useMemo(
    () => (
      <DataTable
        title="View OCTG Nominal Data"
        style={{ color: "rgb(89, 203, 228)" }}
        filtering={true}
        columns={octgNominalState.octgNominalColumns}
        data={octgNominalState.octgNominalData}
        actions={[
          // { icon: () => <ArrowBackIcon color="primary" />, tooltip: 'Go Back', onClick: (event) => handleClickBack(), isFreeAction: true },
          {
            icon: () => <RefreshIcon color="primary" />,
            tooltip: "Refresh",
            onClick: (event) => loadTableData(),
            isFreeAction: true,
          },
          {
            icon: () => <ListIcon data-cy="selectColumns" color="primary" />,
            tooltip: "Select Columns",
            onClick: handleClickShowSelectColumnDialog,
            isFreeAction: true,
          },
          {
            icon: () => <ValidateIcon />,
            tooltip: "Validate Selected Itens",
            onClick: handleClickValidate,
            isFreeAction: true,
            hidden: loadId
              ? currentUser &&
                currentUser.roles.OCTG_DATA_VIEWER &&
                currentUser.roles.OCTG_DATA_VIEWER.DATA_APPROVER
                ? octgNominalState.octgNominalLoad.status === "Pending"
                  ? false
                  : true
                : true
              : true,
          },
          (rowData) => ({
            icon: () => <ErrorIcon color="error" />,
            tooltip: "Show Validation Erros",
            onClick: handleClickShowValidationErrorsDialog,
            hidden: !loadId || rowData.validationResult !== "ERROR",
          }),
          (rowData) => ({
            icon: () => <CheckCircleIcon color="secondary" />,
            tooltip: "Validation Sucsess",
            disabled: true,
            hidden: !loadId || rowData.validationResult !== "SUCCESS",
          }),
        ]}
        detailPanel={(rowData) => {
          return (
            <Grid container className={classes.detailGrid} spacing={1}>
              {octgNominalState.octgNominalDetails.map((column) => {
                return (
                  <Grid key={column.field} item xs={4}>
                    <b>{column.title}:</b> {rowData[column.field]}


                    {console.log("ROW DATA#########", rowData)}
                    {console.log("COLUMn@@@@", column)}
                  </Grid>
                );
              })}
            </Grid>
          );
        }}
      />
    ),
    [
      octgNominalState.octgNominalColumns,
      octgNominalState.octgNominalData,
      octgNominalState.octgNominalLoad,
    ]
  );

  const renderListSelectColumns = useMemo(
    () => (
      <List className={classes.list} dense component="div" role="list">
        {defaultColumns.map((column) => {
          const labelId = `column-list-label-${column.field}`;
          return (
            <ListItem
              key={column.field}
              className={classes.listItem}
              role={undefined}
              dense
              button
              onClick={handleCheckColumn(column)}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  data-cy="listItem"
                  className={classes.checkbox}
                  edge="start"
                  checked={column.checked ? column.checked : false}
                  tabIndex={-1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                className={classes.listItemText}
                primary={column.title}
              />
            </ListItem>
          );
        })}
      </List>
    ),
    [defaultColumns, showSelectColumnDialog]
  );

  const renderListValidationErrors = useMemo(
    () => (
      <Grid container spacing={0}>
        {currentValidationErrors.map((error) => {
          return (
            <Grid key={error.id} container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <b>{error.title}:</b>
              </Grid>
              <Grid container item xs={9} spacing={0}>
                <ul className={classes.validationErrorText}>
                  {error.messages.map((message) => {
                    return <li key={error.id + "_" + message}>{message}</li>;
                  })}
                </ul>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    ),
    [currentValidationErrors, showValidationErrorsDialog]
  );

  return (
    <PageContainer className={classes.root}>
      <PageBody>{renderTableData}</PageBody>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showSelectColumnDialog}
        aria-labelledby="column-selected-dialog"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="column-selected-dialog"
        >
          <IconButton
            color="primary"
            component="span"
            onClick={handleClickCloseSelectColumnDialog}
          >
            <ArrowBackIcon />
          </IconButton>
          Select Columns
        </DialogTitle>

        <DialogContent dividers={false} className={classes.dialogContent}>
          {renderListSelectColumns}
        </DialogContent>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showValidateDialog}
        aria-labelledby="validate-dialog"
      >
        <DialogTitle className={classes.dialogTitle} id="validate-dialog">
          Validate Loads
        </DialogTitle>
        
        <DialogContent dividers={false}>
         
          <DialogContentText> Do you want validate load number \"{loadId}\"? {// eslint-disable-line
          }
          </DialogContentText>

          <DialogContent dividers={false} className={classes.dialogContent}>
            <TextField
              multiline
              rows={20}
              placeholder="Comments"
              id="settings"
              name="settings"
              // error={validateComment !== undefined && !validateComment}
              value={validateComment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setValidateComment(event.target.value)
              }
              variant="outlined"
              fullWidth
            />
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={doClickCloseValidate}
          >
            Close
          </Button>
          <Button
            disabled={!validateComment}
            className={classes.buttonRed}
            variant="contained"
            color="Error"
            startIcon={<ErrorIcon />}
            onClick={doClickValidate("Rejected")}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckCircleIcon />}
            onClick={doClickValidate("Validated")}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={showValidationErrorsDialog}
        aria-labelledby="validation-errors-dialog"
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="validation-errors-dialog"
        >
          <IconButton
            color="primary"
            component="span"
            onClick={handleClickCloseValidationErrorsDialog}
          >
            <ArrowBackIcon />
          </IconButton>
          Validation Errors
        </DialogTitle>

        <DialogContent dividers={false} className={classes.dialogContent}>
          {renderListValidationErrors}
        </DialogContent>
      </Dialog>

      <LoadingOverlay open={isLoading} />
    </PageContainer>
  );
};

export default OctgViewNominalData;
